<template>
  <div class="bg-white">
  <div class="page-wrapper">
	<!-- Preloader -->
	<!-- <div id="preloader">
		<div class="loader">
			<div class="load-circle"><div></div><div></div></div>
		</div>
	</div> -->
    <!-- Preloader end-->
    <Loader v-if="loading"></Loader>
    <div v-else>
    <PreLoader />
      

    <!-- Page Content -->
    <div class="page-content">
		<div class="container">
			<div class="account-area">
        <BackButton></BackButton>
				<!-- <a href="javascript:void(0);" class="back-btn dz-flex-box">
					<i class="icon feather icon-chevron-left"></i>
				</a> -->
        
        <!-- Toast Start -->
        <!-- <div v-if="isToastMessageVisible" class="position-fixed mb-2 top-1 end-1 toast toast-danger style-1 fade show" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="3982" data-bs-autohide="false">
          <div class="toast-header">
            <strong class="me-auto ms-2">Message</strong>
            <button class="btn btn-close position-relative p-1" type="button" @click="hideToast" aria-label="Close">
              <i class="icon feather icon-x"></i>
            </button>
          </div>
          <div class="toast-body"> {{ toastMessage }}</div>
        </div> -->
        <!-- Toast End -->

        <Toast :message="toastMessage" :visible.sync="isToastMessageVisible" />

				<div class="section-head ps-0">
					<h3>Please Enter your Login Credentials</h3>
				</div>
				<form>
          <div v-if="false">
            <div class="input-group dz-select">
              <div class="input-group-text"> 
                <div>
                  <!-- <select class="form-control custom-image-select image-select" v-model="countryCode">
                    <option data-thumbnail="/assets/images/flags/ethiopia.svg" selected>+251</option>
                    <option data-thumbnail="/assets/images/flags/australia.png">+61</option>
                    <option data-thumbnail="/assets/images/flags/india.png">+91</option>
                    <option data-thumbnail="/assets/images/flags/uae.png">+971</option>
                    <option data-thumbnail="/assets/images/flags/us.png">+1</option>
                  </select> -->
                  <select ref="imageSelect" class="form-control custom-image-select image-select" v-model="countryCode">
                    <option v-for="option in options" :key="option.value" :value="option.value" :data-thumbnail="option.thumbnail">
                      {{ option.text }}
                    </option>
                  </select>

                </div>
              </div>
              <input type="number" v-model="phoneNo" class="form-control" placeholder="Phone Number">
              
            </div>
          </div>
          <div class="mb-3 input-group input-group-icon ">
            <div class="input-group-text">
              <div class="input-icon">
                <i class="icon feather icon-at-sign"></i>
              </div>
            </div>
            <input type="text" class="form-control" v-model="username" id ="username" placeholder="Enter username">								
          </div>
          <div class="mb-3 input-group input-group-icon ">
            <div class="input-group-text">
              <div class="input-icon">
                <i class="icon feather icon-lock"></i>
              </div>
            </div>

            <!-- <div class="field has-addons"> -->
              <!-- <div class="control is-expanded"> -->

                <input v-if="showPassword" type="text" class="form-control" v-model="password" id ="password" placeholder="Enter password">		
                <input v-else type="password" class="form-control" v-model="password" id ="password" placeholder="Enter password">
              <!-- </div>	 -->
              <!-- <div class="control"> -->
                <div class="input-group-text" @click="toggleShow">
                  <span class="input-icon is-right">
                    <i class="icon feather" :class="{ 'icon-eye-off': showPassword, 'icon-eye': !showPassword }"></i>
                  </span>
                </div>
                

          </div>
          
				</form>	
        
      
			</div>
        </div>
    </div>
    <!-- Page Content End -->
	<!-- Footer -->
    <!-- <div class="footer fixed bg-white">
		<div class="container">
			<a href="otp.html" class="btn btn-lg btn-gradient w-100 dz-flex-box btn-shadow rounded-xl">Next</a>
		</div>
	</div> -->
  <div class="footer fixed bg-white">
		<div class="container">
			<a @click="login" class="btn btn-lg btn-gradient w-100 dz-flex-box btn-shadow rounded-xl">
        <div v-if="isFetchingLogin" class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="mx-3">
          Login 
        </div>
      </a>
        
        
      <!-- <div class="divider border-success"></div>

      <div class="h-100 d-flex align-items-center justify-content-center">
        Or
      </div> -->

      
      <div class="divider border-dark"></div>

      <a @click="handleNextClick" class="btn btn-alert btn-lg btn-secondary w-100 dz-flex-box btn-shadow rounded-xl"> 
        <div v-if="isFetchingRegister" class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="mx-3">
          Register 
        </div>
      </a>
        
		</div>
	</div>
	<!-- Footer -->
  <!-- <ButtonFooter :onClick="fetchHasPhoneNo" next="/otp" label="Next"></ButtonFooter> -->
  
  </div>
  
</div>
</div>

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import PreLoader from './PreLoader.vue';
import ButtonFooter from './ButtonFooter.vue';
import BackButton from '@/components/BackButton.vue';
import Toast from './Toast.vue';
import Loader from './Loader.vue';
import $ from 'jquery';
import axios from 'axios';
import store from '@/store';
import { getUserDataByPhone, getChatSocketPort, postLogin } from '@/api';
import 'jquery';





@Component({
components: {
  ButtonFooter,
  PreLoader,
  Loader,
  BackButton,
  Toast
},
})
export default class Login extends Vue {
  private phoneNo: string = '';
  private countryCode: string = '';
  private loading: boolean = true;
  private options: object[] = [];
  private selectedOption: string = '';
  private selectPicker: any | undefined = undefined;
  private isFetchingRegister: boolean = false;
  private isFetchingLogin: boolean = false;
  private toastMessage: string = '';
  private isToastMessageVisible: boolean = false;
  private showPassword: boolean = false;
  private username: string = '';
  private password: string = '';

 
  


  clearUserData() {
    this.$store.commit('clearUserData');
  }

  fetchChatSocket() {
    var protocol = 'ws://';
    if(window.location.protocol === 'https:') {
            protocol = 'wss://';
    }
    
    this.$store.commit('setChatSocketHost', `${protocol}${process.env.VUE_APP_SOCKET_HOST}`);

    // getChatSocketPort()
    //   .then(response => {
        
    //     alert(JSON.stringify(response));
    //     if (response.data.webSocketPort) {
    //       this.$store.commit('setChatSocketPort', response.data.webSocketPort);
    //       this.$store.commit('setChatSocketHost', `${protocol}${process.env.VUE_APP_SOCKET_HOST}`);
    //       console.log(`wewew ${protocol}${process.env.VUE_APP_SOCKET_HOST}`);
    //       // this.$store.commit('setChatSocketHost', `${protocol}${process.env.VUE_APP_SOCKET_HOST}:${response.data.webSocketPort}`);
    //     }
    //   })
    //   .catch(error => {
    //       console.error('Error fetching data:', error);
    //   });
  }

  private handleNextClick() {
    
      this.$router.push('/username');
  }

  login() {
      // this.$store.commit('clearState');
      // this.fetchChatSocket();

      this.isFetchingLogin = true;

      // alert(this.username);

      const credentials = {
        'username': this.username, 
        'password': this.password
      }

      postLogin(credentials)
        .then(response => {

          
            this.$store.commit('clearState');
            
            this.fetchChatSocket();
            console.log(JSON.stringify(response.data));
            if (response.data.success) {
                this.$store.commit('setUserData', response.data.data);
                this.$router.push('/feed');
            } else {
              this.toastMessage = response.data.errors;
              this.isToastMessageVisible = true;
              this.isFetchingLogin = false;
              return;
            }
            
        })
        .catch(error => {
          // this.toastMessage = JSON.stringify(error.response.data.errors);
          this.toastMessage = "Incorrect username or password";
          this.isToastMessageVisible = true;
          this.isFetchingLogin = false;
          console.error('Error fetching data:', error);
        })
        .finally(() => {
          this.isFetchingLogin=false;
        });
  }

  fetchHasPhoneNo(method: string) {

    if (!this.phoneNo) {

      this.toastMessage = 'Incorrect Phone Number';
      this.isToastMessageVisible = true;
      return;
    }

    // this.loading = true;
    // this.clearUserData();
    if (method == 'register') {

      this.isFetchingRegister = true;
    } else if (method == 'login') {
      this.isFetchingLogin = true;
    }
    this.$store.commit('clearState');
    this.fetchChatSocket();

    // Make an Axios GET request to your API endpoint
    // axios.get(process.env.VUE_APP_API_HOST + '/users?expand=userProfile&phone_no=' + encodeURIComponent(this.countryCode + this.phoneNo))
    // axios.get(process.env.VUE_APP_API_HOST + '/users?expand=userProfile&phone_no=' + encodeURIComponent(this.countryCode + this.phoneNo))
    getUserDataByPhone(encodeURIComponent(this.countryCode + this.phoneNo))
        .then(response => {
          // console.log(JSON.stringify(response.data.items));
          // Update the 'relationshipGoals' array with the response data
          if (response.data.items && response.data.items.length > 0) {

            console.log(JSON.stringify(response.data));

            if (method == 'login') {
              this.$store.commit('setUserData', response.data.items[0])
              this.$router.push('/otp');
            } else if (method == 'register') {

              this.toastMessage = 'Phone Number Already Registered';
              this.isToastMessageVisible = true;
              // alert('already exists');

            }

          } else {

            if (method == 'login') {
              this.toastMessage = 'Wrong Login';
              this.isToastMessageVisible = true;
              // alert('user does not exist');
            } else if (method == 'register') {

              
              this.$store.commit('setUserData', { phone_no: this.countryCode + this.phoneNo });
              this.$router.push('/username');
            }
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        })
        .finally(() => {
          if (method == 'register') {
            this.isFetchingRegister = false;
          } else if (method == 'login') {
            this.isFetchingLogin = false;
          }
        });
  }

  mounted() {
    this.$nextTick(() => {
      this.handleImageSelect();
    })


    // const data = {	
    //   "username": "chilliwack_admin",
	  //   "password": "chilliwack_admin_password"
    // };
    // axios.create({
    //   baseURL: 'https://api.chilliwack.itexxiahosting.com',
    //   withCredentials: true, // Include cookies if your API requires authentication
    // }).
    // get('https://api.chilliwack.itexxiahosting.com/roles')
    //   .then(response => {
    //     console.log(response.data);
    //   })
    //   .catch(error => {
    //       console.error(error);
    //       if (error.response) {
    //         // Server responded with a status other than 200 range
    //         console.error(`Error: ${error.response.data.message || 'Login failed.'}`);
    //       } else if (error.request) {
    //         // Request was made but no response was received
    //         console.error('No response from server. Please try again later.');
    //       } else {
    //         // Something happened in setting up the request that triggered an Error
    //         console.error('Error in request setup: ' + error.message);
    //       }
    //     });
  }

  updated() {
    // Refresh selectpicker whenever the options change
    this.$nextTick(() => {
      this.handleImageSelect();
      // this.selectPicker = this.$refs.imageSelect as HTMLSelectElement;
      // this.selectPicker.selectpicker();

      // $(this.$refs.imageSelect).selectpicker('refresh');
    });
  }

  handleImageSelect() {

    interface JQuery<TElement = HTMLElement> {
      selectpicker(action?: string): JQuery<TElement>;
    }

      this.selectPicker = this.$refs.imageSelect;
      const $selectPicker = $(this.selectPicker);

      $selectPicker.find('option').each((idx, elem) => {
        const $option = $(elem);
        const imageUrl = $option.attr('data-thumbnail');
        if (imageUrl) {
          $option.attr(
            'data-content',
            `<img src='${imageUrl}' alt='thumbnail' style='width: 20px; height: 20px; margin-right: 10px;' /> ${$option.text()}`
          );
        }
      });

      // this.$nextTick(() => {
      //   ($selectPicker as any).selectpicker('refresh');
      // })
  }

  data() {
    
    return {
      countryCode: '+251',
      loading: false,

      selectedOption: '+251',
      options: [
        // { value: '+251', text: '+251', thumbnail: '../assets/images/flags/ethiopia.svg'},
        // { value: '+61', text: '+61', thumbnail: '../assets/images/flags/australia.png'},
        // { value: '+91', text: '+91', thumbnail: '../assets/images/flags/india.png'},
        // { value: '+971', text: '+971', thumbnail: '../assets/images/flags/uae.png'},
        // { value: '+1', text: '+1', thumbnail: '../assets/images/flags/us.png'}
        { value: '+251', text: '+251', thumbnail: "../assets/images/flags/ethiopia.svg" },
        { value: '+61', text: '+61', thumbnail: "/assets/images/flags/australia.png" },
        { value: '+91', text: '+91', thumbnail: '../assets/images/flags/india.png' },
        { value: '+971', text: '+971', thumbnail: '/assets/images/flags/uae.png' },
        { value: '+1', text: '+1', thumbnail: '/assets/images/flags/us.png' }
      ],

      showPassword: false,
    }

  }

  toggleShow() {
      this.showPassword = !this.showPassword;
  }

  
}
</script>
