<template>
  <div class="bg-white">
    <div class="page-wrapper">
      <PreLoader></PreLoader>

    <!-- <div v-if="loading"> -->
      <!-- Page Content -->
      <div class="page-content">
      <div class="container">
        <div class="account-area">
          <BackButton></BackButton>
          
          <Toast :message="toastMessage" :visible.sync="isToastMessageVisible" />
          <!-- <a href="javascript:void(0);" class="back-btn dz-flex-box">
            <i class="icon feather icon-chevron-left"></i>
          </a> -->
          <div class="section-head ps-0">
            <h3>Enter your Location</h3>
          </div>

          <div class="card style-3">
            <div class="card-header">
              <h6 class="title mb-0 font-14 font-w500">City</h6>
            </div>
            <div class="card-body">
              <a href="javascript:void(0);" class="setting-input" data-bs-toggle="offcanvas" data-bs-target="#offcanvasCities" aria-controls="offcanvasCities">
                <span class="select-lang">{{ cityName ? cityName : 'Select City' }}</span>
              </a>
            </div>
          </div>

          <div class="mb-2 input-group input-group-icon input-mini">
            <div class="input-group-text">
              <div class="input-icon">
                <i class="icon feather icon-map-pin"></i>
              </div>
            </div>
            <input type="text" class="form-control" v-model="addressLine" id ="address_line" placeholder="Enter Address Line">								
          </div>
        </div>
          </div>
      </div>
      <!-- Page Content End -->
    <!-- <ButtonFooter :onClick="next" label="Next"></ButtonFooter> -->

    <!-- Cities Offcanvas -->
    <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasCities" aria-modal="true" role="dialog">
      <button type="button" class="btn-close drage-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      <div class="offcanvas-header share-style m-0 pb-0">
        <h6 class="title">City</h6>
      </div>
      <div class="offcanvas-body">
        <div class="radio style-2">
          <label class="radio-label" v-for="cityEntry in cities" :key="cityEntry.id">
            <input type="radio" v-model="city" :value="cityEntry.id" :name="radio2"
            @click="getCity(cityEntry.city)"
            >
            <span class="checkmark">
              <span class="text">{{ cityEntry.city }}</span>
              <span class="check"></span>
            </span>
          </label>
<!-- 
          <label class="radio-label">
            <input type="radio" checked="checked" name="radio3">
            <span class="checkmark">						
              <span class="text">Straight</span>
              <span class="check"></span>							
            </span>
          </label>
          <label class="radio-label">
            <input type="radio" name="radio3">
            <span class="checkmark">
              <span class="text">Gay</span>
              <span class="check"></span>							
            </span>
          </label>
          <label class="radio-label">
            <input type="radio" name="radio3">
            <span class="checkmark">
              <span class="text">Lesbian</span>	
              <span class="check"></span>							
            </span>
          </label>
          <label class="radio-label">
            <input type="radio" name="radio3">
            <span class="checkmark">
              <span class="text">Bisexual</span>	
              <span class="check"></span>							
            </span>
          </label>
          <label class="radio-label">
            <input type="radio" name="radio3">
            <span class="checkmark">
              <span class="text">Asexual</span>	
              <span class="check"></span>							
            </span>
          </label>
          <label class="radio-label">
            <input type="radio" name="radio3">
            <span class="checkmark">
              <span class="text">Queer</span>	
              <span class="check"></span>							
            </span>
          </label>
          <label class="radio-label">
            <input type="radio" name="radio3">
            <span class="checkmark">
              <span class="text">Demisexual</span>	
              <span class="check"></span>							
            </span>
          </label> -->
        </div>
      </div>
    </div>
    <ButtonFooter :onClick="handleNextClick" label="Next"></ButtonFooter>
          <!-- <Button @click="next">Next</Button> -->
      <!-- </div> -->
  </div>
</div>

</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import PreLoader from './PreLoader.vue';
import ButtonFooter from './ButtonFooter.vue';
import BackButton from './BackButton.vue';
import Toast from './Toast.vue';


@Component({
components: {
  ButtonFooter,
  PreLoader,
  BackButton,
  Toast
},
})

export default class Location extends Vue {

  private location: string = '';
  private city: number = 0;
  private cities: object[] = [];
  private loading: boolean = true;
  private cityName: string = '';
  private userData: object = {};
  private addressLine: string = '';
  private toastMessage: string = '';
  private isToastMessageVisible: boolean = false;

  private handleNextClick() {

      if(!this.city || !this.addressLine){
        this.toastMessage = 'Fields cannot be left blank';
        this.isToastMessageVisible = true;
        return;
      }
    
      this.$store.commit('setUserData', { location: this.addressLine + ', ' + this.cityName});
      this.$store.commit('setUserData', { location_city: this.city});
      this.$store.commit('setUserData', { location_city_name: this.cityName});
      this.$store.commit('setUserData', { address_line: this.addressLine});
      
      
      this.$router.push('/birthdate');
  }

  getCity(cityEntry: string)
  {
    console.log(JSON.stringify(cityEntry));
    this.cityName = cityEntry;
    
  }

  data() {
    this.userData = this.$store.state.userData;
    const user_userData = this.$store.state.userData;
    return {
      city: user_userData.location_city ?? 1,
      location: user_userData.location ?? '',
      cityName: user_userData.location_city_name ?? 'Select City',
      addressLine: user_userData.address_line ?? '',
      loading : true,
      cities: this.loadCities(),
      selectedCity: null,
      radio2: 'CitiesGroup',
    }
  }

  mounted() {
    // If your data is static, you can set loading to false directly in the mounted hook
    this.loading = false;
    this.loadCities();
  }

  loadCities() {
      // Import the JSON file
      fetch("/assets/json/ethiopia_cities.json")
        .then((response) => response.json())
        .then((data) => {      
          data.sort((a: any, b: any) => a.city.localeCompare(b.city));
          this.cities = data;
          // this.cities = data.sort((a, b) => a.city.localeCompare(b.city));
        })
        .catch((error) => {
          console.error("Error loading cities:", error);
        });
    }
  

};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
