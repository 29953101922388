<template>
  <div class="bg-white">
    <div class="page-wrapper">
      <Loader v-if="loading"></Loader>
      <div v-else>
        <PreLoader />

        <!-- Page Content -->
        <div class="page-content">
          <div class="container">
            <div class="account-area">
              <BackButton/>

              <Toast :message="toastMessage" :visible.sync="isToastMessageVisible" />

              <div class="section-head ps-0">
                <h3>Please Enter your Phone Number</h3>
              </div>
              <!-- <form> -->
                <div class="input-group dz-select">
                  <div class="input-group-text"> 
                    <div>
                      
                      <select ref="imageSelect" class="form-control custom-image-select image-select" v-model="countryCode">
                        <option v-for="option in options" :key="option.value" :value="option.value" :data-thumbnail="option.thumbnail">
                          {{ option.text }}
                        </option>
                      </select>

                    </div>
                  </div>
                  <input type="number" v-model="phoneNo" class="form-control" placeholder="Phone Number">
                  
                </div>
            
              <!-- </form>	 -->
              
            
            </div>
          </div>
        </div>
        <ButtonFooter :onClick="fetchHasPhoneNo" label="Next" :isFetching="isFetching"></ButtonFooter>
      </div>
    </div>
  </div>

</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import PreLoader from './PreLoader.vue';
import ButtonFooter from './ButtonFooter.vue';
import BackButton from './BackButton.vue';
import Loader from './Loader.vue';
import Toast from './Toast.vue';
import { getUserDataByPhone } from '@/api';


@Component({
components: {
  ButtonFooter,
  PreLoader,
  BackButton, 
  Toast,
  Loader
},
})


export default class PhoneNumber extends Vue {

  private firstName: string = '';
  private loading: boolean = true;
  private toastMessage: string = '';
  private isToastMessageVisible: boolean = false;
  private userData: object = {};
  private phoneNo: string = '';
  private countryCode: string = '';
  private isFetching: boolean = false;
  


  private handleNextClick() {
    if (!this.firstName) {

      this.toastMessage = 'Field cannot be left blank';
      this.isToastMessageVisible = true;
      return;
    }
      this.$store.commit('setUserData', { name: this.firstName });
      this.$router.push('/email');
  }

  fetchHasPhoneNo() {

    if (!this.phoneNo) {

      this.toastMessage = 'Incorrect Phone Number';
      this.isToastMessageVisible = true;
      return;
    }

    this.isFetching = true;

    getUserDataByPhone(encodeURIComponent(this.countryCode + '-' + this.phoneNo))
      .then(response => {
        
        if (response.data.items && response.data.items.length > 0) {

          this.toastMessage = 'Phone Number Already Registered';
          this.isToastMessageVisible = true;
          this.isFetching = false;
          return;
        } 

          this.$store.commit('setUserData', { phone_no: this.countryCode + '-' + this.phoneNo });
          this.$store.commit('setUserData', { country_code: this.countryCode });
          this.$store.commit('setUserData', { phone: this.phoneNo });

          this.$router.push('/firstname');
        
            
         
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  data() {
    this.userData = this.$store.state.userData;
    const user_userData = this.$store.state.userData;
    return {
      loading : true,
      phoneNo: user_userData?.phone ?? '',
      firstName: user_userData?.name ?? '',


      countryCode: user_userData?.country_code ?? '+251',

      selectedOption: '+251',
      options: [
        { value: '+251', text: '+251', thumbnail: "../assets/images/flags/ethiopia.svg" },
        { value: '+61', text: '+61', thumbnail: "/assets/images/flags/australia.png" },
        { value: '+91', text: '+91', thumbnail: '../assets/images/flags/india.png' },
        { value: '+971', text: '+971', thumbnail: '/assets/images/flags/uae.png' },
        { value: '+1', text: '+1', thumbnail: '/assets/images/flags/us.png' }
      ],
    }
  }

  mounted() {
    // If your data is static, you can set loading to false directly in the mounted hook
    this.loading = false;
  }
  

};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
